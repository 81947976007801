<!--
 * @Descripttion:
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2022-05-25 14:37:00
 * @LastEditors: tangjz
 * @LastEditTime: 2023-06-09 16:41:34
-->
<template>
  <ul class="announcement-list" v-if="announcementList.length > 0" v-loading="loading">
      <li class="announcement-list-item" v-for="item in announcementList" :key="item.id">
        <div class="announcement-item-icon">
          <svg v-if="item.announcement_cate_id===1" width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 1L6.01 10.75 32 11 19 1z" fill="#333"/><rect x="6" y="10" width="26" height="20" rx="2" fill="#333"/><rect x="10" y="4" width="18" height="26" rx="2" fill="#DCDAFF"/><rect x="14" y="9" width="10" height="2" rx="1" fill="#7972F0"/><rect x="14" y="13" width="10" height="2" rx="1" fill="#7972F0"/><path fill-rule="evenodd" clip-rule="evenodd" d="M7.221 29.843c.24.101.503.157.779.157h22a2.012 2.012 0 0 0 .81-.17A2 2 0 0 0 32 28V12c0-.286-.06-.558-.169-.805L19 21 6.169 11.195A1.993 1.993 0 0 0 6 12v16a2 2 0 0 0 1.221 1.843z" fill="#7972F0"/></svg>
          <svg v-else-if="item.announcement_cate_id===2" width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="8" y="5" width="22" height="28" rx="2" fill="#7972F0"/><rect x="12" y="18" width="14" height="2" rx="1" fill="#DCDAFF"/><path d="M26 5v10l-2.5-1.364L21 15V5h5z" fill="#DCDAFF"/><rect x="12" y="24" width="14" height="2" rx="1" fill="#DCDAFF"/></svg>
          <svg v-else width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="6.372" y="18.089" width="5" height="14" rx="2.5" transform="rotate(-6.36 6.372 18.089)" fill="#333"/><path d="M7 14a36.827 36.827 0 0 0 18.191-9.245L26 4v28l-.09-.084A39.045 39.045 0 0 0 6 22l1-8z" fill="#7972F0"/><ellipse cx="26" cy="18" rx="5" ry="14" fill="#DCDAFF"/><path d="M27 18c0 2.21-2.239 4-5 4-1 0-1-1.79-1-4s0-4 1-4c2.761 0 5 1.79 5 4z" fill="#F6F9FA"/><path d="M4 18.107a4.69 4.69 0 0 1 4.07-4.65L11.5 13l.5 10.5-4.174-.783A4.69 4.69 0 0 1 4 18.107z" fill="#7972F0"/></svg>
        </div>
        <div class="announcement-item-content">
          <h1>
            <span @click="announcementDel(item.id)">{{item.title}}</span>
            <span class="announcement-new" v-if="item.read_status === 0">NEW</span>
            <span class="announcement-stick" v-if="item.stick_status === 1">置顶</span>
          </h1>
          <h3> {{ item.content && removeTag(item.content)}} </h3>
          <div class="announcement-content-foorter">
            <span class="content-orgin" :class="item.cate.id===0?'content-blue-orgin':'content-blue-purple'">#{{item.cate.name}}#</span>
            <span class="content-corporate-name"> {{item.author}} </span>
            <span> {{formatDate(item.updated_at)}} </span>
          </div>
        </div>
      </li>
    </ul>
    <!-- 右边内容为空 -->
    <div class="announcement-empty" v-else-if="!loading">
      <EmptyData
        :size="noEmptyData.size"
        :image="noEmptyData.image"
        :description="noEmptyData.description"
      />
    </div>
</template>
<script>
import { formatDate } from '@/utils/filterHtml.js'
import EmptyData from '@/components/EmptyData.vue'
import { useRouter } from 'vue-router'
import { computed, reactive, toRefs } from '@vue/reactivity'
import { useStore } from 'vuex'

export default {
  components: {
    EmptyData
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      loading: computed(() => props.isLoading),
      announcementList: computed(() => props.data),
      noEmptyData: { // 用于没有数据展示的图
        size: '80',
        image: 'no-announcement',
        description: '目前还没有公告'
      }
    })
    // 进入详情页
    function announcementDel (id) {
      if (!state.announcementList.filter(i => i.id === id)[0].read_status) {
        state.announcementList.filter(i => i.id === id)[0].read_status = 1
      }
      store.commit('announcement/setAnnounceData', { list: JSON.stringify(state.announcementList), top: document.documentElement.scrollTop || document.body.scrollTop })
      router.push({
        name: 'announcementDel',
        params: {
          id
        }
      })
    }

    // 渲染文字去除标签
    function removeTag (str) {
      return str.replace(/(<[^>]+>|&nbsp;)/g, '')
    }
    return {
      ...toRefs(state),
      formatDate,
      announcementDel,
      removeTag
    }
  }

}
</script>

<style lang="less" scoped>

.announcement-list{
  background: #fff;
  width: 791px;
  height: 100%;
  border: 1px solid #F1F1F1;
  box-sizing: border-box;
  border-radius: 6px;
  li:not(:first-child) {
    border-top: 1px solid #F1F1F1;
  }
}

.announcement-list-item{
  width: 791px;
  height: 118px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 23px;
  .announcement-item-icon{
    width: 38px;
    height: 38px;
    margin: -38px 9px 0 0;
  }
  .announcement-item-content{
    h1{
      display: flex;
      align-items: center;
      font-family: Microsoft YaHei;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: @default-text-color;
      span:first-child{
        display: inline-block;
        max-width: 600px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
      }
      span:first-child:hover{
        color: #7972F0;
      }
    }
    h3{
      font-family: Microsoft YaHei;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #999999;
      margin: 4px 0 8px;
      width: 697px;
      overflow:hidden;
      white-space:nowrap;
      text-overflow:ellipsis;
    }
    div{
      font-family: Microsoft YaHei;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      flex: none;
      color: #999999;
      order: 2;
      flex-grow: 0;
      .content-corporate-name{
        margin: 0 12px;
      }
      .content-orgin{
        color: #FF8401;
      }
      .content-blue-purple{
        color: #7972F0;
      }
    }
  }
}

.announcement-new{
  display: inline-block;
  background:@tick-color;
  border-radius: 5px;
  margin-left: 2px;
  padding: 4px 4px;
  font-family: Microsoft YaHei;
  font-style: normal;
  font-weight: bold;
  font-size:14px;
  transform:scale(0.72);
  line-height: 18px;
  height: 25px;
  color: #FFFFFF;
}

.announcement-stick{
  display: inline-block;
  border-radius: 5px;
  padding: 2px 4px;
  font-family: Microsoft YaHei;
  font-style: normal;
  font-weight: bold;
  font-size:12px;
  transform:scale(0.83);
  line-height: 18px;
  color: #FF8401;
  border: 1px solid #FF8401;
}

.el-empty{
  width: 719px;
  height: 404px;
  box-sizing: border-box;
  border: 1px solid #F1F1F1;
  border-radius: 6px;
}
</style>
