<!--
 * @Descripttion: 公告
 * @Date: 2022-01-19 10:17:17
 * @Author: lingjunbin@imyfone.cn
 * @LastEditTime: 2023-06-09 15:10:29
 * @LastEditors: tangjz
-->
<template>
  <div class="announcement" v-infinite-scroll="loadMore" infinite-scroll-immediate="false" infinite-scroll-distance="0">
    <!-- 左边分区 -->
    <el-affix :offset="Number(71)">
      <ul class="announcement-cate" v-loading="loading">
        <li
          v-for="(item, index) in cateList"
          :key="index"
          :class="{ active: index === store.state.announcement.clickItem ? true : false }"
          @click="changeItem(index,item)"
        >
          <el-badge :value="store.state.announcement.unReadAnt" v-if="store.state.announcement.unReadAnt > 0 && index === 1">
            <div>{{ item.name }}</div>
          </el-badge>
          <div v-else>{{ item.name }}</div>
        </li>
      </ul>
    </el-affix>

    <!-- 右边内容 -->
    <AnnouncementList :isLoading="loading" :data="announcementData" />
  </div>
</template>

<script>
import AnnouncementList from './component/announcementList.vue'
import { getCateList, getAnnouncementList } from '@/apis/announcement.js'
import { reactive, toRefs, onMounted, computed, nextTick } from 'vue'
import store from '@/store'
export default {
  components: {
    AnnouncementList
  },
  beforeRouteLeave (to, from, next) {
    console.log(to, from)
    // 公告page 重置 不是从详情页回来的
    if (!to.path.includes('announcement')) {
      store.commit('announcement/setAnnouncementData', { limit: 10, page: 1, cate_id: 0, type: 1 })
      store.commit('announcement/setClickItem', 0)
      store.commit('announcement/setAnnounceData')
    }
    next()
  },
  setup () {
    const state = reactive({
      loading: false, // loading效果
      cateList: [], // 左边列表数据
      announcementData: [], // 右边的数据
      localAnnounceData: computed(() => store.state.announcement.announceData)
    })

    onMounted(() => {
      getAnnouncement()
      getCateList().then((res) => {
        if (res.code === 0) {
          state.cateList = res.data
          store.commit('announcement/setunReadAnt', res.data[1].count)
        }
      })
    })

    // 获取公告列表数据
    async function getAnnouncement () {
      if (!state.announcementData.length) {
        state.loading = true
      }
      // console.log(store.state.announcement.announcementData)
      await getAnnouncementList(store.state.announcement.announcementData).then(res => {
        if (res.code === 0) {
          if (state.localAnnounceData.list) {
            state.announcementData = JSON.parse(state.localAnnounceData.list)
            nextTick(() => {
              window.scrollTo({
                left: 0,
                top: state.localAnnounceData.top,
                behavior: 'instant'
              })
              store.commit('announcement/setAnnounceData')
            })
          } else {
            state.announcementData.push(...res.data.data)
          }
          if (store.state.announcement.announcementData.type === 2) {
            store.commit('announcement/setunReadAnt', res.data.total)
          }
          if (res.data.total === state.announcementData.length && state.announcementData.length !== 0) {
            store.commit('setNoMore', true)
          }
        }
      }).catch(err => {
        console.log(err)
      })
      store.commit('setLoadMore', false)
      state.loading = false
    }

    // 点击左边列表时触发
    function changeItem (index, item) {
      store.commit('announcement/setAnnounceData')
      store.commit('announcement/setClickItem', index)
      store.commit('announcement/setAnnouncementData', { cate_id: item.id, type: item.type, page: 1, limit: 10 })
      state.announcementData = []
      store.commit('setNoMore', false)
      store.commit('setLoadMore', false)
      getAnnouncement()
      if (index === 2) {
        state.noEmptyData?.image && (state.noEmptyData.image = 'no-notice')
      } else if (index === 3) {
        state.noEmptyData?.image && (state.noEmptyData.image = 'no-rules')
      } else {
        state.noEmptyData?.image && (state.noEmptyData.image = 'no-announcement')
      }
      state.noEmptyData?.description && (state.noEmptyData.description = `目前还没有${item.name}`)
      document.scrollingElement.scrollTop = 0
    }

    // loadMore无限滚动
    function loadMore () {
      if (state.announcementData.length !== 0 && !store.state.noMore && !store.state.loadMore) {
        const page = ++store.state.announcement.announcementData.page
        store.commit('setLoadMore', true)
        store.commit('announcement/setAnnouncementData', { page })
        getAnnouncement()
      }
    }

    return {
      ...toRefs(state),
      getAnnouncement,
      changeItem,
      loadMore,
      store
    }
  }
}
</script>

<style lang="less" scoped>
.announcement{
  display: flex;
  .announcement-cate {
    margin-right: 8px;
    padding: 16px;
    border: 1px solid #f1f1f1;
    border-radius: 6px;
    width: 157px;
    height: fit-content;
    background-color: @colorWhite;
    li {
      font-size: 14px;
      text-align: left;
      height: 30px;
      line-height: 30px;
      margin-bottom: 10px;
      color: @default-text-color;
      padding-left: 8px;
      border-radius: 28px;
      div{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      :deep(.is-fixed) {
        margin-top: 15px;
        margin-right: 20px;
        background: red;
      }
      &:hover {
        background-color: #f2f1fe;
        color: @active-text-color;
        cursor: pointer;
      }
    }
    .el-badge__content.is-fixed {
      right: 24px;
    }
    div {
      display: block;
    }
    .active {
      font-weight: bold;
      color: @active-text-color;
    }
  }
}

</style>
